<template>
  <div>
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("ONBOARD.NEW_CLIENT") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form">
        <div class="card-body mx-4">
          <div class="row">
            <p class="h6 m-0">{{ $t("ONBOARD.SELECT_ORGANIZATION") }}</p>
          </div>
          <div class="row pt-5">
            <!-- CUI -->
            <b-input-group
              :prepend="$t('ONBOARD.CUI')"
              class="form-group col-4 p-0 mr-4"
            >
              <b-form-input v-model="currentOrganization.cui"></b-form-input>
            </b-input-group>
            <button
              @click="searchOrganization"
              class="btn btn-success form-group"
            >
              {{ $t("ONBOARD.SEARCH") }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <!-- CUSTOMER DETAILS -->
    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("ONBOARD.NEW_CLIENT_DETAILS") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <form class="form" :key="gotData">
        <div class="card-body mx-4">
          <!-- Detailed organization info -->
          <div class="row">
            <p class="h6">
              {{ $t("ONBOARD.REVIEW_CLIENT_INFO") }}
            </p>
          </div>
          <div class="row mt-3">
            <!-- CUI -->
            <b-input-group
              :prepend="$t('ONBOARD.CUI')"
              class="form-group col-4 p-0"
            >
              <b-form-input
                class="input-group h-auto"
                v-model="currentOrganization.cui"
              ></b-form-input>
            </b-input-group>

            <!-- Company full name -->
            <b-input-group
              :prepend="$t('ONBOARD.COMPANY_NAME')"
              class="form-group col p-0 mx-3"
            >
              <b-form-input
                class="input-group h-auto"
                v-model="currentOrganization.name"
              ></b-form-input>
            </b-input-group>

            <!-- Energy type -->
            <b-input-group
              data-app
              :prepend="$t('FILTER.ENERGY_TYPE_LABEL')"
              class="form-group col-3 p-0"
            >
              <b-form-select
                v-model="currentOrganization.energyType"
                :options="energyTypes"
              ></b-form-select>
              <!-- <v-autocomplete
                class="input-group custom-select"
                v-model="currentOrganization.energyType"
                :items="energyTypes"
                autocomplete="not-today"
                solo
                flat
                dense
                :no-data-text="$t('FILTER.NO_DATA')"
              ></v-autocomplete> -->
            </b-input-group>
          </div>

          <div class="row mt-3">
            <!-- Contract start date -->
            <b-input-group
              :prepend="$t('ONBOARD.CONTRACT_START_DATE')"
              class="form-group col-4 mr-3 p-0"
            >
              <b-form-datepicker
                v-model="currentOrganization.contractDate"
              ></b-form-datepicker>
            </b-input-group>

            <!-- Company address -->
            <b-input-group
              :prepend="$t('ONBOARD.COMPANY_ADDRESS')"
              class="form-group col p-0"
            >
              <b-form-input
                v-model="currentOrganization.address"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="row mt-3">
            <!-- Company address region -->
            <!-- <b-input-group
              :prepend="$t('ONBOARD.COMPANY_ADDRESS_REGION')"
              class="col-4 p-0"
            >
              <b-form-input v-model="currentOrganization.region"></b-form-input>
            </b-input-group> -->

            <!-- Company address county-->
            <b-input-group
              :prepend="$t('ONBOARD.COMPANY_ADDRESS_COUNTY')"
              class="col mr-3 p-0"
            >
              <b-form-input v-model="currentOrganization.county"></b-form-input>
            </b-input-group>

            <!-- Company address city-->
            <b-input-group
              :prepend="$t('ONBOARD.COMPANY_ADDRESS_CITY')"
              class="col p-0"
            >
              <b-form-input v-model="currentOrganization.city"></b-form-input>
            </b-input-group>
          </div>

          <!-- PROCESS -->
          <!-- <div class="row">
            <button
              @click="processOrganization"
              class="my-5 btn btn-success btn-block"
            >
              {{ $t("ONBOARD.PROCESS") }}
            </button>
          </div> -->
        </div>
      </form>
      <!--end::Card Body-->
    </div>

    <!-- SIMILAR CLIENTS -->
    <!-- <div class="card card-custom gutter-b">
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("ONBOARD.SIMILAR_CLIENTS") }}
        </h3>
      </div>
      <form class="form">
        <div class="card-body mx-4">
          <div class="row">
            <p class="h6" v-if="!currentOrganization.energyType">
              {{ $t("ONBOARD.SELECT_ENERGY_TYPE") }}
            </p>
          </div>
          <div class="row">
            <b-input-group
              data-app
              :prepend="$t('FILTER.COMPANY_CUI_LABEL')"
              class="col pb-0 pl-0"
            >
              <v-autocomplete
                class="input-group custom-select"
                v-model="similarCompany"
                :items="organizations"
                autocomplete="not-today"
                item-text="cui"
                solo
                flat
                dense
                return-object
                :disabled="!currentOrganization.energyType"
              ></v-autocomplete>
            </b-input-group>
            <b-input-group
              data-app
              :prepend="$t('FILTER.COMPANY_NAME_LABEL')"
              class="col pb-0 pr-0"
            >
              <v-autocomplete
                class="input-group custom-select"
                v-model="similarCompany"
                :items="organizations"
                autocomplete="not-today"
                item-text="name"
                solo
                flat
                dense
                return-object
                :disabled="!currentOrganization.energyType"
              ></v-autocomplete>
            </b-input-group>
          </div>
          <div class="row">
            <button
              @click="addNewOrganization"
              class="btn btn-success btn-block mt-5"
            >
              {{ $t("ONBOARD.ADD_NEW_CLIENT") }}
            </button>
          </div>

          <div class="row d-flex justify-content-center">
            <div
              class="col-3 text-center text-white rounded px-3 mx-4 py-10 mt-4"
              v-for="(item, index) in similarOrganizations"
              :class="`company-${index % 3}`"
              :key="item.id"
            >
              <h2 class="font-weight-bolder mb-3">{{ item.name }}</h2>
              <button
                class="btn btn-light"
                @click="removeSimilarOrganization(item)"
              >
                {{ $t("ONBOARD.REMOVE") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div> -->

    <div class="card card-custom gutter-b">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("ONBOARD.PREDICTIONS") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <div class="card-body mx-4">
        <!-- CHARTS -->
        <!-- <div>
          <p class="h6">{{ $t("ONBOARD.REAL_GRAPH_DATA_DESC") }}</p>
          <apexchart
            v-if="similarOrganizations.length > 0"
            :options="chartOptions"
            :series="similarOrganizationsSeries"
            type="line"
            height="250"
          ></apexchart>
        </div> -->

        <div class="row mb-4 align-items-end">
          <p class="h6 m-0 mb-4 mr-4">{{ $t("ONBOARD.UPLOAD_NOTE") }}</p>
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              class="btn btn-primary"
              :class="[manual ? 'active' : '']"
              @click="manual = true"
            >
              <input
                type="radio"
                name="options"
                id="manual"
                autocomplete="off"
                checked
              />
              {{ $t("ONBOARD.MANUAL_UPLOAD") }}
            </label>
            <label
              class="btn btn-primary"
              :class="[!manual ? 'active' : '']"
              @click="manual = false"
            >
              <input
                type="radio"
                name="options"
                id="upload"
                autocomplete="off"
              />
              {{ $t("ONBOARD.FILE_UPLOAD") }}
            </label>
          </div>
        </div>
        <!-- 24 blocks of data -->
        <div class="row" v-if="manual">
          <p class="h6 m-0 mb-4">{{ $t("ONBOARD.LAST_YEAR") }}</p>
        </div>
        <div class="row" v-if="manual">
          <b-input-group
            v-for="index in 12"
            :key="index"
            :prepend="months[index - 1]"
            class="col-2 p-0 my-4 mx-2"
          >
            <b-form-input
              v-model="currentOrganization.year1[index - 1]"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="row" v-if="manual">
          <p class="h6 m-0 my-4">{{ $t("ONBOARD.TWO_YEARS_AGO") }}</p>
        </div>
        <div class="row" v-if="manual">
          <b-input-group
            v-for="index in 12"
            :key="index"
            :prepend="months[index - 1]"
            class="col-2 p-0 my-4 mx-2"
          >
            <b-form-input
              v-model="currentOrganization.year2[index - 1]"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="row" v-if="manual">
          <p class="h6 m-0 mb-4 font-italic">{{ $t("ONBOARD.NOTE") }}</p>
        </div>
        <div class="" v-if="!manual">
          <!-- <b-input-group
            data-app
            :prepend="$t('IMPORT.GRANULARITY')"
            class="form-group col-3 p-0"
          >
            <b-form-select
              v-model="granularity"
              :options="granularities"
            ></b-form-select>
          </b-input-group> -->

          <p class="row">
            {{ $t("ONBOARD.DOWNLOAD") }}
            <a class="mx-2" href="#" @click="onExportExampleFile">
              {{ $t("ONBOARD.EXAMPLE") }}
            </a>
          </p>

          <b-form-file
            class="row"
            v-model="file"
            :placeholder="$t('IMPORT.UPLOAD_PLACEHOLDER')"
            :drop-placeholder="$t('IMPORT.UPLOAD_DROP')"
          ></b-form-file>
        </div>

        <div class="row">
          <button
            @click="saveInputValues"
            :disabled="saveInProgress"
            class="my-5 btn btn-success btn-block"
          >
            <span>{{ $t("ONBOARD.SAVE") }}</span>
            <v-progress-circular
              indeterminate
              color="primary"
              :size="15"
              :width="2"
              v-if="saveInProgress"
              class="ml-3"
            ></v-progress-circular>
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom gutter-b" v-if="showPredictions">
      <!--begin::Header-->
      <div class="card-header">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t("ONBOARD.PREDICTION_GRAPH_DESC") }}
        </h3>
      </div>
      <!--end::Header-->

      <!--begin::Card Body-->
      <div class="card-body mx-4">
        <!-- CHARTS -->
        <div class="mt-10">
          <h4 class="text-center mb-0">{{ currentOrganization.name }}</h4>
          <apexchart
            style="height: 200px"
            type="bar"
            height="200"
            :options="chartOptions"
            :series="currentOrganizationSeries"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnergyService from "@/core/services/energy.service";
import Swal from "sweetalert2";

export default {
  name: "OnBoard",
  components: {},
  data() {
    return {
      gotData: "",
      months: [],
      showPredictions: false,
      organizations: [],
      energyOrganizations: [],
      gasOrganizations: [],
      currentOrganization: {
        cui: "",
        name: "",
        year1: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        year2: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
      },
      companyInfo: {},
      similarCompany: {
        cui: "",
        name: "",
      },
      // similarOrganizations: [],
      energyTypes: ["Electric", "Gas"],
      allOrganizations: [],
      currentOrganizationSeries: [],
      chartOptions: {},
      saveInProgress: false,
      // granularity: "",
      // granularities: [],
      file: null,
      manual: true,
    };
  },
  methods: {
    exportCsv(name, csv) {
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = `${name}.csv`;
      anchor.click();
    },
    onExportExampleFile() {
      let csv = `Year 1\nJan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec\n\nYear 2\nJan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, Dec\n\n`;
      this.exportCsv("example", csv);
    },
    async uploadFiles() {
      const _this = this;
      const promise = new Promise((resolve, reject) => {
        if (this.file) {
          let reader = new FileReader();
          reader.readAsText(this.file);

          reader.onload = (e) => {
            let csvdata = e.target.result;
            let data = csvdata.split("\n");
            if (data.length >= 2) {
              _this.currentOrganization.year1 = data[2]
                .split(",")
                .map((val) => {
                  val = val.trim().replace("\r", "");
                  if (val.length == 0) {
                    return null;
                  } else {
                    return val;
                  }
                });
            }
            if (data.length >= 5) {
              _this.currentOrganization.year2 = data[5]
                .split(",")
                .map((val) => {
                  val = val.trim().replace("\r", "");
                  if (val.length == 0) {
                    return null;
                  } else {
                    return val;
                  }
                });
            }
            resolve();
          };
        } else {
          reject();
        }
      });
      return promise;
    },
    async saveInputValues() {
      let response;

      this.saveInProgress = true;
      this.companyInfo.name_on_site = this.currentOrganization.name;
      this.companyInfo.address = this.currentOrganization.address;
      this.companyInfo.town = this.currentOrganization.city;
      this.companyInfo.county = this.currentOrganization.county;

      if (this.file != null) {
        await this.uploadFiles();
      }

      try {
        response = await EnergyService.getOnboardPrediction({
          company: this.companyInfo,
          year1: this.currentOrganization.year1,
          year2: this.currentOrganization.year2,
          type: this.currentOrganization.energyType,
          contractDate: this.currentOrganization.contractDate,
        });
      } catch (e) {
        this.saveInProgress = false;
        Swal.fire({
          title: "",
          text: this.$t("ERROR.DEFAULT"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      }

      this.saveInProgress = false;

      this.currentOrganizationSeries = this.getSeries(response);
      this.chartOptions = this.getChartOptions(response);
      this.showPredictions = true;
    },
    async searchOrganization() {
      this.companyInfo = await EnergyService.getOnboardInfo(
        this.currentOrganization.cui,
        this.currentOrganization.name
      );
      this.currentOrganization.cui = this.companyInfo.cui;
      this.currentOrganization.name = this.companyInfo.name_on_site;
      this.currentOrganization.address = this.companyInfo.address;
      this.currentOrganization.city = this.companyInfo.town;
      this.currentOrganization.county = this.companyInfo.county;

      this.gotData = "" + Math.random();
    },
    getLanguage() {
      let language = localStorage.getItem("language");
      language = language === "ro" ? "ro-RO" : "en-US";
      return language;
    },
    getCategories(response) {
      let abbrMonths = [];
      let months = response.x ? response.x : [];
      for (let i = 0; i < months.length; i++) {
        abbrMonths.push(
          new Date(
            months[i].year,
            months[i].month - 1, // TODO check
            1
          ).toLocaleString(this.getLanguage(), {
            month: "short",
          })
        );
      }
      return abbrMonths;
    },
    getSeries(response) {
      return [
        {
          name: this.$t("DASHBOARD.PREDICTED"),
          data: response.y,
        },
      ];
    },
    getChartOptions(response) {
      return {
        chart: {
          type: "bar",
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.getCategories(response),
        },
        yaxis: {
          show: true,
          showAlways: true,
          title: {
            text: this.$t("DASHBOARD.CONSUME"),
          },
          labels: {
            formatter: function (value) {
              return Number(value).toLocaleString();
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return Number(value).toLocaleString() + " MW";
            },
          },
        },
        fill: {
          opacity: 1,
        },
      };
    },
  },
  mounted() {
    // this.granularities = [this.$t("IMPORT.DAY"), this.$t("IMPORT.MONTH")];

    this.months = [];
    for (let i = 0; i <= 11; i++) {
      this.months.push(
        new Date(2022, i, 1).toLocaleString(this.getLanguage(), {
          month: "short",
        })
      );
    }
  },
};
</script>
